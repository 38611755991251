import React from 'react'

import Layout from '../components/layout'
import ServicesOne from '../components/services/ServicesOne'
import ServicesThree from '../components/services/ServicesThree'
import ServicesTwo from '../components/services/ServicesTwo'

const Services = props => (
  <Layout lang="pt" title="Serviços" meta={{description: 'Um parceiro tecnológico global ao seu serviço. Da estratégia a produtos digitais e integrações.'}} rootProps={{ className: 'page services' }}>
    <ServicesOne lang='pt' />
    <ServicesTwo  lang='pt'/>
    <ServicesThree  lang='pt'/>
  </Layout>
)

export default Services
